
// Content styles
.block-text,
.biography {
	line-height: 1.7;

	// Default paragraphs
	p {
		margin: 0 0 $paragraph-margin;

		// &.intro {
		// 	color: $color-sub;
		// 	font-family: $font-family-sub;
		// 	font-size: $h5;
		// }
	}

	// reset any <ul> that will appear in a template here
	// maintain vertical rhythm with paragraph margin
	ul[class] {
		@extend %reset-ul;
		margin: 0 0 $paragraph-margin;
	}

	// New default <ul> lists
	ul {
		@extend %default-ul;
	}

	// New default <ol> lists
	ol {
		@extend %default-ol;
	}
}

