
// Default .button class
.button {
    @extend %button;
    display: inline-block;
	padding: ($block-padding * 1/2) ($block-padding * 2/3);

    &:hover {
        @extend %hover;
    }

    a {
        color: inherit;
        transition: inherit;
    }
}
